import React, { useContext, useEffect, useState } from "react";
import styles from './sidebar.module.css';
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import MainContext from "../../context";

function Logout() {

    const logout = () => {
        localStorage.clear();
        window.location.replace('/login');
    }

    return (
        <div className={styles.logoutContainer} onClick={() => logout()}>
            <div className={styles.logout}>
                <div className={styles.logoutTip} />
                <div className={'d-flex align-items-center'} style={{ zIndex: 100 }}>
                    <FontAwesomeIcon className={'pe-2'} icon={faArrowRightFromBracket} />
                    <div style={{ fontSize: '14px', letterSpacing: '8%', fontWeight: 350 }}>Log Out</div>
                </div>
            </div>
        </div>
    )
}

function SidebarItem({ item, selected, selectedSubmenu, isConfig }) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    return (
        <div className={`${styles.sidebarItem} ${selected ? styles.sidebarItemSelected : ''} ${open && styles.opened}`} onClick={() => {
            if (item.route) {
                navigate(item.route);
            }
        }}>
            {selected && <div className={styles.sidebarItemPill} />}
            <div className={'d-flex align-items-center justify-content-start'} style={{ paddingTop: '8px' }} onClick={() => {
                if (item.submenus) {
                    setOpen((prev) => !prev);
                }
            }}>
                <img alt={item.name} src={`/sidebar/${selected || open ? 'active' : 'inactive'}/${item.icon}`} className={'me-4'} width={'25px'} />
                <div>{item.name}</div>
            </div>
            <div className={'d-flex justify-content-start flex-column align-items-start'}>
                {(open || isConfig) &&
                    <>
                        {item.submenus?.map((submenu, sindex) => {
                            return (
                                <div className={`${styles.submenu} ${selectedSubmenu === sindex && isConfig ? styles.selectedSubmenu : ''}`} onClick={() => {
                                    navigate(submenu.route);
                                }}>
                                    <div>{submenu.name}</div>
                                </div>
                            )
                        })}
                    </>
                }
            </div>
        </div>
    )
}

function Profile() {
    const [showLogout, setShowLogout] = useState(false);
    return (
        <div className={styles.profile} onClick={() => setShowLogout((prev) => !prev)}>
            <div className={styles.profileImage} />
            <div>
                <div style={{ fontWeight: '550', fontSize: '15px' }}>Admin Admin</div>
                <div style={{ color: '#A9AFD0', fontSize: '12px' }}>Mi Perfil</div>
            </div>
            {showLogout && <Logout />}
        </div>
    );
}

export default function Sidebar() {
    const { settings } = useContext(MainContext);
    useEffect(() => {

    }, []);

    let items = []

    const jsonPermissions = localStorage.getItem('permissions');
    if (jsonPermissions !== null && jsonPermissions !== "undefined") {
        const permissions = JSON.parse(jsonPermissions);
        const allowedNames = permissions?.filter((x) => x.access_level !== "none").map((x) => x.permission.name);
        const configRoutes = ['Empresa', 'Accesos', 'Permisos', 'Turnos', 'Master de Empleados', 'Master de Pulseras'];
        const allowedConfig = configRoutes.filter((x) => allowedNames.includes(x));
        const showConfig = allowedConfig.length > 0;
        items = [
            ...(allowedNames?.includes('Dashboard') ? [{ name: 'Dashboard', icon: '/dashboard.png', route: '/' }] : []),
            ...(allowedNames?.includes('Resultado Turno') ? [{ name: "Resultado Turno", icon: '/result.png', route: '/results' }] : []),
            ...(allowedNames?.includes('Detalle Usuario') ? [{ name: "Detalle Usuario", icon: '/detail.png', route: '/detailed' }] : []),
            ...(showConfig ? [{
                name: "Configuración", icon: '/companies.png', submenus: [
                    ...(allowedNames?.includes('Empresa') ? [{ name: "Empresa", route: '/config/company' }] : []),
                    ...(allowedNames?.includes('Accesos') ? [{ name: "Accesos", route: '/config/access' }] : []),
                    ...(allowedNames?.includes('Permisos') ? [{ name: "Permisos", route: '/config/permissions' }] : []),
                    ...(allowedNames?.includes('Turnos') ? [{ name: "Turnos", route: '/config/shifts' }] : []),
                    ...(allowedNames?.includes('Master de Empleados') ? [{ name: "Master de Empleados", route: '/config/employees' }] : []),
                    ...(allowedNames?.includes('Master de Pulseras') ? [{ name: "Master de Pulseras", route: '/config/bands' }] : []),
                ]
            }] : []),
        ]
    }


    return (
        <div className={styles.container}>
            <div className={`${styles.logoContainer}`}>
                <img alt={'stracontech'} src={'/branding/stracontech_logo_final.png'} height={'40px'} />
            </div>
            <div className={'d-flex justify-content-center w-100'}>
                <div className={styles.sidebarDivider} style={{ marginBottom: '40px' }} />
            </div>
            <div>
                {items?.map((item, index) => {
                    return <SidebarItem selected={settings?.sidebar === index} isConfig={settings?.sidebar === 3} selectedSubmenu={settings?.submenu} key={index} item={item} />
                })}
            </div>
            <div className={styles.sidebarBottom}>
                <div className={styles.sidebarDivider} />
                <Profile />
            </div>
        </div>
    )
}