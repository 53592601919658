// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --sidebar-width: 236px;
}

.App {
  text-align: center;
}

html,
body,
#root {
  height: 100%;
  background-color: #F4F6FB;
  font-family: Mulish, sans-serif;
}

.searchBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
}

.searchBox>div:first-of-type {
  background-color: #FFFFFF;
  border: 1px solid #5863B44D;
  border-right: none;
  padding: 6px 12px;
  border-radius: 10px 0 0 10px;
}

.searchBox input[type='text'] {
  outline: none;
  border: 1px solid #5863B44D;
  border-left: none;
  border-radius: 0 10px 10px 0;
  color: #5863B499;
  padding: 6px 0 6px 0;
  width: 100%;
}


/* RS RANGE DATE PICKER */
.rs-picker,
.rs-input,
.rs-input-group,
.rs-picker-popup {
  height: 100%;
}

.rs-picker-popup.rs-picker-popup-daterange {
  z-index: 1000000;
}

.rs-input-group.rs-input-group-inside {
  border: 1px solid #ccc;
}

div.rs-input-group.rs-input-group-inside:hover {
  border: 1px solid #4A4DB9;
}

rs-calendar-header-title.rs-btn-subtle {
  font-weight: 700;
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;;;EAGE,YAAY;EACZ,yBAAyB;EACzB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,kBAAkB;EAClB,iBAAiB;EACjB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,iBAAiB;EACjB,4BAA4B;EAC5B,gBAAgB;EAChB,oBAAoB;EACpB,WAAW;AACb;;;AAGA,yBAAyB;AACzB;;;;EAIE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B","sourcesContent":[":root {\n  --sidebar-width: 236px;\n}\n\n.App {\n  text-align: center;\n}\n\nhtml,\nbody,\n#root {\n  height: 100%;\n  background-color: #F4F6FB;\n  font-family: Mulish, sans-serif;\n}\n\n.searchBox {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 500px;\n}\n\n.searchBox>div:first-of-type {\n  background-color: #FFFFFF;\n  border: 1px solid #5863B44D;\n  border-right: none;\n  padding: 6px 12px;\n  border-radius: 10px 0 0 10px;\n}\n\n.searchBox input[type='text'] {\n  outline: none;\n  border: 1px solid #5863B44D;\n  border-left: none;\n  border-radius: 0 10px 10px 0;\n  color: #5863B499;\n  padding: 6px 0 6px 0;\n  width: 100%;\n}\n\n\n/* RS RANGE DATE PICKER */\n.rs-picker,\n.rs-input,\n.rs-input-group,\n.rs-picker-popup {\n  height: 100%;\n}\n\n.rs-picker-popup.rs-picker-popup-daterange {\n  z-index: 1000000;\n}\n\n.rs-input-group.rs-input-group-inside {\n  border: 1px solid #ccc;\n}\n\ndiv.rs-input-group.rs-input-group-inside:hover {\n  border: 1px solid #4A4DB9;\n}\n\nrs-calendar-header-title.rs-btn-subtle {\n  font-weight: 700;\n  text-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
