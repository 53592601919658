import React, {useContext, useEffect, useState} from "react";
import Header from "../../dashboard/components/header";
import CompanyDetailHeader from "./components/company_detail_header/company_detail_header";
import CompanyData from "./sections/company_data";
import Group from "./sections/group";
import Project from "./sections/project";
import Guard from "./sections/guard";
import Email from "./sections/email";
import MainContext from "../../../context";
import GroupModal from "./modals/group";
import ProjectModal from "./modals/project";
import GuardModal from "./modals/guard";
import EmailModal from "./modals/email";
import {useParams} from "react-router-dom";
import api from "../../../api";

export default function CompanyDetail() {
    const [selected, setSelected] = React.useState(0);
    const {setSettings} = useContext(MainContext);
    const {id} = useParams();
    const [company, setCompany] = useState({});

    useEffect(() => {
        document.title = "Sleeptech - Empresa";
        setSettings((prev) => {return {
            ...prev, sidebar: 3, submenu: 0
        }});

        api.get(`subenterprises/${id}/`).then((response) => {
            setCompany(response.data);
        });
    }, [setSettings]);

    const renderSection = () => {
        if(selected === 0) return (<CompanyData company={company}/>)
        if(selected === 1) return (<Group company={company}/>)
        if(selected === 2) return (<Project company={company}/>)
        if(selected === 3) return (<Guard company={company}/>)
        if(selected === 4) return (<Email company={company}/>)
    }
    
    return (
        <div>
            <GroupModal/>
            <ProjectModal/>
            <GuardModal/>
            <EmailModal/>

            <Header title={'Empresa'} subtitle={'Stracon'}/>
            <CompanyDetailHeader selected={selected} setSelected={setSelected} company={company}/>
            <div className={'mb-3'}/>
            {renderSection()}
        </div>
    );
}