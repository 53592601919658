import React from "react";
import styles from "../../screens/dashboard/components/risk/index.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";

export default function HeaderDropdown({name, setOpen, open}) {
    return (
        <div onClick={() => setOpen((prev) => !prev)} className={styles.dropdown}>
            <div>{name}</div>
            <FontAwesomeIcon icon={!open ? faChevronUp : faChevronDown} color={'#5863B4'}/>
        </div>
    )
}