// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sections_formContainer__cpI-5 {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 5% 10%;
    color: #5863B4;
    font-family: 'Lexend Variable', sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.08em;
    text-align: left;
}

.sections_formContainer__cpI-5 input[type='text'] {
    border: #C3CDEF 1px solid;
    border-radius: 10px;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    min-height: 46px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
}

.sections_button__IICS2 {
    margin-top: 40px;
    font-family: 'Lexend Variable', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0.08em;
    text-align: left;
    color: #5863B4;
    background-color: #CFD4F8;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 264px;
    height: 57px;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/screens/configuration/company/sections/sections.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,cAAc;IACd,0CAA0C;IAC1C,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,kCAAkC;IAClC,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,0CAA0C;IAC1C,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".formContainer {\n    background-color: #FFFFFF;\n    border-radius: 10px;\n    padding: 5% 10%;\n    color: #5863B4;\n    font-family: 'Lexend Variable', sans-serif;\n    font-size: 13px;\n    font-weight: 500;\n    line-height: 29px;\n    letter-spacing: 0.08em;\n    text-align: left;\n}\n\n.formContainer input[type='text'] {\n    border: #C3CDEF 1px solid;\n    border-radius: 10px;\n    width: 100%;\n    font-family: 'Poppins', sans-serif;\n    min-height: 46px;\n    padding-left: 10px;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 24px;\n    text-align: left;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    outline: none;\n}\n\n.button {\n    margin-top: 40px;\n    font-family: 'Lexend Variable', sans-serif;\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 29px;\n    letter-spacing: 0.08em;\n    text-align: left;\n    color: #5863B4;\n    background-color: #CFD4F8;\n    border-radius: 9px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 264px;\n    height: 57px;\n    user-select: none;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `sections_formContainer__cpI-5`,
	"button": `sections_button__IICS2`
};
export default ___CSS_LOADER_EXPORT___;
