import React, { useEffect, useState } from "react";
import { Line, CartesianGrid, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export default function SleepGraph({ rec_data }) {
    const [data, setData] = useState({});

    useEffect(() => {
        if (Object.keys(rec_data).length < 1) return
        const sleep_samples = rec_data?.sleep_time_samples;
        const sleep_stage_samples = rec_data?.sleep_stage_samples;
        let graph_data = [];
        let last_sample = null;
        for (let i = 0; i < sleep_samples.length; ++i) {
            const start = sleep_samples[i]?.timestamp
            const end = sleep_samples[i]?.wakeup_time
            if (last_sample) {
                const lastEnd = last_sample?.wakeup_time
                graph_data.push({
                    name: new Date((lastEnd + start) / 2).toTimeString().slice(0, 5),
                    awake: 1,
                    timestamp: (lastEnd + start) / 2
                });
            }
            graph_data.push({
                name: new Date(sleep_samples[i]?.timestamp).toTimeString().slice(0, 5),
                awake: 0,
                timestamp: start
            });
            graph_data.push({
                name: new Date(sleep_samples[i]?.wakeup_time).toTimeString().slice(0, 5),
                awake: 0,
                timestamp: end
            });
            last_sample = sleep_samples[i];
        }
        if (sleep_stage_samples.length < 1) return;
        let last_stage = null
        sleep_stage_samples.forEach((stage) => {
            const current = stage?.timestamp
            if (last_stage) {
                const lastEnd = last_stage?.timestamp
                const isAwake = last_stage.stage === 5 || last_stage.stage === 0;

                if (isAwake) {
                    graph_data.push({
                        name: new Date((lastEnd + current) / 2).toTimeString().slice(0, 5),
                        awake: 1,
                        timestamp: (lastEnd + current) / 2
                    });
                }
                graph_data.push({
                    name: new Date(last_stage?.timestamp).toTimeString().slice(0, 5),
                    awake: 0,
                    timestamp: lastEnd
                });
                graph_data.push({
                    name: new Date(stage?.timestamp).toTimeString().slice(0, 5),
                    awake: 0,
                    timestamp: current
                });
            }
            last_stage = stage;
        });
        // Sort by timestamp
        graph_data.sort((a, b) => a.timestamp - b.timestamp);
        setData(graph_data);
    }, [rec_data]);
    return (
        <div className={'mt-4 d-flex justify-content-center align-items-center flex-column px-2'}>
            <ResponsiveContainer width="100%" height={150}>
                <LineChart
                    data={data}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <XAxis dataKey="name" />
                    <Line dataKey="awake" stroke="#A36EFE" type="monotone" dot={false} />
                    <YAxis padding={{ top: 10, bottom: 10 }} type="category"
                        // dataKey={(data) => data.awake === 1 ? 'Despertar' : 'Dormir'}
                        allowDataOverflow />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip formatter={(value) => value === 1 ? 'Despertar' : 'Dormir'} />
                </LineChart>
            </ResponsiveContainer>

        </div>
    )
}