import React, {useState} from 'react';
import styles from "./index.module.css";
import {Label, Text} from "../../components/ajonjolib/inputs/ajonjolinput";
import {useNavigate} from "react-router-dom";
import api from "../../api";

function Lock() {
    return (
        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.3333 8.69401V5.69401C13.3333 2.56068 10.8 0.0273438 7.66667 0.0273438C4.53333 0.0273438 2 2.56068 2 5.69401V8.69401H0V20.0273H15.3333V8.69401H13.3333ZM3.33333 5.69401C3.33333 3.29401 5.26667 1.36068 7.66667 1.36068C10.0667 1.36068 12 3.29401 12 5.69401V8.69401H3.33333V5.69401ZM14 18.694H1.33333V10.0273H14V18.694Z"
                fill="white"/>
            <path
                d="M6.99992 14.494V16.694H8.33325V14.494C8.73325 14.2607 8.99992 13.8273 8.99992 13.3607C8.99992 12.6273 8.39992 12.0273 7.66659 12.0273C6.93325 12.0273 6.33325 12.6273 6.33325 13.3607C6.33325 13.8607 6.59992 14.294 6.99992 14.494Z"
                fill="white"/>
        </svg>
    )
}

function Email({setStep}) {
    const [email, setEmail] = useState('')

    const submit = () => {
        api.post('/password/request', {email}).then((response) => {
            if(response.status !== 200) return;
            setStep((prev) => prev + 1);
        });
    }

    return (
        <div className={styles.content}>
            <div className={styles.title}>Reestablecer la contraseña</div>

            <div className={styles.notes}>Indícanos tu dirección de correo electrónico o nombre de usuario y te
                enviaremos un enlace para que
                puedas volver a acceder a tu cuenta.
            </div>

            <Label style={{color: '#fff'}}>Dirección de correo electrónico</Label>
            <Text value={email} onChange={(val) => setEmail(val)}/>

            <div className={'d-flex align-items-center justify-content-center'}>
                <div className={styles.button} onClick={submit}>Enviar Enlace</div>
            </div>
        </div>
    )
}

function NewPassword({setStep}) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const submit = () => {
        api.post('/password/reset/{uidb64}/{token}/')
    }

    return (
        <div className={styles.content}>
            <div className={styles.title}>Reestablecer la contraseña</div>

            <div className={styles.notes}>Ingresa tu nueva contraseña</div>

            <Label className={'mb-2'} style={{color: '#fff', display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
                <Lock/>
                <div className={'ms-2'}>Nueva Contraseña</div>
            </Label>
            <Text type={'password'}/>
            <div className={'mt-4'}/>
            <Label className={'mb-2'} style={{color: '#fff', display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
                <Lock/>
                <div className={'ms-2'}>Confirmar Contraseña</div>
            </Label>
            <Text type={'password'}/>

            <div className={'d-flex align-items-center justify-content-center'}>
                <div className={styles.button} onClick={() => setStep((prev) => prev + 1)}>Confirmar</div>
            </div>
        </div>
    )
}

function Success() {
    const navigate = useNavigate();
    return (
        <div className={styles.content}>
            <div className={styles.title}>Contraseña reestablecida correctamente</div>

            <div className={styles.notes}>Tu contraseña ha sido reestablecida correctamente.</div>

            <div className={styles.darkNote}>Retorna  a la pantalla de Login para acceder a tu cuenta con tu nueva contraseña.</div>

            <div className={'d-flex align-items-center justify-content-center'}>
                <div className={styles.button} onClick={() => {
                    navigate('/login');
                }}>Volver
                </div>
            </div>
        </div>
    )
}

export default function ResetPassword({}) {
    const [step, setStep] = useState(0);

    const getStep = () => {
        if (step === 0) return <Email setStep={setStep}/>
        else if (step === 1) return <NewPassword setStep={setStep}/>
        else if (step === 2) return <Success/>
    }

    return (
        <div className={styles.container}>
            <img className={styles.logo} alt={'stracon'} src={'/branding/stracontech_white.png'} width={'223px'}/>
            <img className={styles.background} src={'/login_background_dark.png'} alt={'background'}/>

            {getStep()}
        </div>
    )
}