import React, {useEffect, useState} from "react";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import styles from './modals.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {eventBus} from "../../../../components/ajonjolib/toasts/toast/toast";

function DownloadButton({children}) {
    return (
        <div className={styles.downloadButton}>
            <FontAwesomeIcon icon={faFileExcel} color={'#FFF'} className={'me-3'}/>
            {children}
        </div>
    )
}

export default function ExportModal({title}) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        eventBus.subscribe('export_modal_open', (rec_data) => {
            setIsOpen(true);
        });
        eventBus.subscribe('export_modal_close', (data) => {setIsOpen(false)});
    }, []);

   /* const downloadTemplate = () => {

    }

    const downloadData = () => {

    }*/

    return (
        <div>
            {isOpen &&
                <Modal title={title} desc={'Exportar'} close={() => setIsOpen(false)}>
                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{flexBasis: '45%', textAlign: 'left'}}>
                            <div style={{fontSize: '15px', color: '#494B50', lineHeight: '16px'}}>Descargar Plantilla</div>
                            <div style={{fontSize: '12px'}}>La plantilla a descargar se encuentra vacía</div>
                        </div>

                        <div style={{flexBasis: '45%'}}>
                            <DownloadButton>
                                <div>Descargar Plantilla</div>
                            </DownloadButton>
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{flexBasis: '45%', textAlign: 'left'}}>
                            <div style={{fontSize: '15px', color: '#494B50', lineHeight: '16px'}}>Descargar Registro</div>
                            <div style={{fontSize: '12px'}}>El registro a descargar toma a los usuarios y datos registrados en el Master de Empleados</div>
                        </div>

                        <div style={{flexBasis: '45%'}}>
                            <DownloadButton>
                                <div>Descargar Registro</div>
                            </DownloadButton>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}