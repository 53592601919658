import React, {useEffect, useState} from "react";
import {DateRangePicker, Label, Select, Text} from "../../../../components/ajonjolib/inputs/ajonjolinput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";

export default function GuardCreate() {
    const [form, setForm] = useState({active: true});
    const [projects, setProjects] = useState([]);

    const submit = () => {
        api.post('guards/', form).then((response) => {
            window.location.reload();
        });
    }

    useEffect(() => {
        api.get('projects/').then((response) => {
            setProjects(response.data)
        });
    }, []);

    return (
        <>
            <div>
                <Label>Proyecto</Label>
                <Select value={form['project']} onChange={(val) => {
                    setForm({...form, project: val[0], project_group: val[0]?.project_group?.id});
                }} options={projects?.map((x) => {
                    return {
                        value: x.id,
                        name: x.name ? x.name : 'Sin Nombre'
                    }
                })}/>
                <div className={'mb-4'}/>
                <Label>Guardia</Label>
                <Text value={form['name']} onChange={(val) => {
                    setForm({...form, name: val});
                }}/>
                <div className={'mb-4'}/>
                <Label>Rango de Fecha</Label>
                <DateRangePicker onChange={(val) => {
                    if(val?.length > 1) {
                        let start_date = new Date(val[0]);
                        let year = start_date.getFullYear();
                        let month = (start_date.getMonth() + 1).toString().padStart(2, '0');
                        let day = start_date.getDate().toString().padStart(2, '0');
                        let start_formattedDate = `${year}-${month}-${day}`;
                        let end_date = new Date(val[1]);
                        year = end_date.getFullYear();
                        month = (end_date.getMonth() + 1).toString().padStart(2, '0');
                        day = end_date.getDate().toString().padStart(2, '0');
                        let end_formattedDate = `${year}-${month}-${day}`;
                        console.log(start_formattedDate, end_formattedDate);
                        setForm({...form, start_date: start_formattedDate, end_date: end_formattedDate});
                    }
                }}/>
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'}/>
                <div style={{color: '#5863B4'}}>Guardar</div>
            </div>
        </>
    )
}