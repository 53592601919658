// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_container__hTWL6 {
    display: flex;
    font-family: 'Lexend Variable', sans-serif;
    line-height: 29px;
    letter-spacing: 0.08em;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 40px;
}

.header_title__QEojX {
    color: #29273A;
    font-size: 28px;
    font-weight: 500;
}

.header_subtitle__eL0Uf {
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.08em;
    text-align: left;
    color: #5863B4;
}`, "",{"version":3,"sources":["webpack://./src/screens/dashboard/components/header/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0CAA0C;IAC1C,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".container {\n    display: flex;\n    font-family: 'Lexend Variable', sans-serif;\n    line-height: 29px;\n    letter-spacing: 0.08em;\n    text-align: left;\n    align-items: center;\n    justify-content: space-between;\n    padding: 10px;\n    margin-bottom: 40px;\n}\n\n.title {\n    color: #29273A;\n    font-size: 28px;\n    font-weight: 500;\n}\n\n.subtitle {\n    font-size: 20px;\n    font-weight: 500;\n    line-height: 29px;\n    letter-spacing: 0.08em;\n    text-align: left;\n    color: #5863B4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `header_container__hTWL6`,
	"title": `header_title__QEojX`,
	"subtitle": `header_subtitle__eL0Uf`
};
export default ___CSS_LOADER_EXPORT___;
