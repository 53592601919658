import React, { useEffect, useState } from "react";
import HeaderDropdown from "../../../../components/header_dropdown";
import styles from "./index.module.css";

function BigModule({ data, generalSelect }) {
    const [selectedData, setSelectedData] = useState(data?.both);

    useEffect(() => {
        if (generalSelect === 'both') setSelectedData(data?.both);
        else if (generalSelect === 'day') setSelectedData(data?.day);
        else if (generalSelect === 'night') setSelectedData(data?.night);
    }, [generalSelect, data]);

    const filterName = () => {
        if (generalSelect === "both") return "Ambos Turnos";
        else if (generalSelect === "day") return "Día";
        else if (generalSelect === "night") return "Noche";
    }

    return (
        <div className={`${styles.bigContainer}`}>
            <div style={{ padding: '20px' }}>
                <div>Porcentaje de Uso del Dispositivo</div>
                <div className={`${styles.pillContainer} py-3`}>
                    <div className={`${styles.pill}`}>{filterName()}</div>
                </div>
            </div>
            <div>
                <div className={'d-flex justify-content-between'}>
                    <div style={{ color: '#97959D', fontFamily: 'Poppins', fontSize: '12px', fontWeight: 400, lineHeight: '18px', textAlign: 'center' }}>
                        <div className={'py-1'}>Usaron</div>
                        <div className={'d-flex justify-content-center align-items-center'} style={{
                            height: '39px', borderRadius: '0 10px 10px 0', backgroundColor: '#B3C739', width: '152px',
                            color: '#FFFFFF', fontSize: '20px', fontWeight: 'bold'
                        }}>{Math.round(selectedData?.used ? selectedData?.used / (selectedData?.total_users ? selectedData?.total_users : 1) * 100 : 0)}%</div>
                    </div>
                    <div style={{ color: '#97959D', fontFamily: 'Poppins', fontSize: '12px', fontWeight: 400, lineHeight: '18px', textAlign: 'center' }}>
                        <div className={'py-1'}>No Usaron</div>
                        <div className={'d-flex justify-content-center align-items-center'} style={{
                            height: '39px', borderRadius: '10px 0 0 10px', backgroundColor: '#F66565', width: '152px',
                            color: '#FFFFFF', fontSize: '20px', fontWeight: 'bold'
                        }}>{Math.round(selectedData?.not_used ? selectedData?.not_used / (selectedData?.total_users ? selectedData?.total_users : 1) * 100 : 0)}%</div>
                    </div>
                </div>

                <div className={'d-flex mt-3'}>
                    <div style={{
                        background: 'linear-gradient(90deg, rgba(188, 209, 63, 0.3) 0%, rgba(188, 209, 63, 0.7) 100%)',
                        width: `${parseInt(selectedData?.used / selectedData?.total_users * 100)}%`, height: '72px', borderRight: '2px solid #B3C739', borderBottomLeftRadius: '10px',
                    }}></div>
                    <div style={{
                        background: 'linear-gradient(270deg, rgba(246, 101, 101, 0.3) 8.3%, rgba(246, 101, 101, 0.7) 100%)',
                        width: `${parseInt(selectedData?.not_used / selectedData?.total_users * 100)}%`, height: '72px', borderLeft: '2px solid #F66565', borderBottomRightRadius: '10px',
                    }}></div>
                </div>
            </div>
        </div>
    )
}

function SmallModule({ data, generalSelect }) {
    const [selectedData, setSelectedData] = useState(data?.both);

    useEffect(() => {
        if (generalSelect === 'both') setSelectedData(data?.both);
        else if (generalSelect === 'day') setSelectedData(data?.day);
        else if (generalSelect === 'night') setSelectedData(data?.night);
    }, [generalSelect, data]);

    const filterName = () => {
        if (generalSelect === "both") return "Ambos Turnos";
        else if (generalSelect === "day") return "Día";
        else if (generalSelect === "night") return "Noche";
    }

    return (
        <div className={`${styles.smallContainer}`}>
            <div>Cantidad de Usos del Dispositivo</div>
            <div className={`${styles.pillContainer} py-3`}>
                <div className={`${styles.pill}`}>{filterName()}</div>
            </div>

            <div>
                <div className={'d-flex justify-content-between py-3'}>
                    <div>Usaron</div>
                    <div style={{ color: '#7DB338' }}>{selectedData?.used}</div>
                </div>
                <div className={'d-flex justify-content-between py-3'}>
                    <div>No Usaron</div>
                    <div style={{ color: '#F22222' }}>{selectedData?.not_used}</div>
                </div>
            </div>
        </div>
    )
}

export default function Usage({ data, generalSelect }) {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <HeaderDropdown open={open} setOpen={setOpen} name={'Estadísticas de Uso'} />

            {open &&
                <div className={'d-flex justify-content-between'}>
                    <BigModule data={data} generalSelect={generalSelect} />
                    <SmallModule data={data} generalSelect={generalSelect} />
                </div>
            }
        </div>
    )
}
