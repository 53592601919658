// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time_container__W8ki8 {

}

.time_container__W8ki8 input[type='time'] {
    outline: none;
    border: 1px #E2E5F4 solid;
    border-radius: 10px;
    color: #737373;
    background-color: #F4F6FB;
    padding: 8px 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/ajonjolib/inputs/time/index.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,yBAAyB;IACzB,iBAAiB;AACrB","sourcesContent":[".container {\n\n}\n\n.container input[type='time'] {\n    outline: none;\n    border: 1px #E2E5F4 solid;\n    border-radius: 10px;\n    color: #737373;\n    background-color: #F4F6FB;\n    padding: 8px 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `time_container__W8ki8`
};
export default ___CSS_LOADER_EXPORT___;
