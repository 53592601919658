// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usage_bigContainer__DzJXj,
.usage_smallContainer__1XtNP {
    background-color: #FFFFFF;
    border-radius: 10px;
    margin-top: 20px;
    color: #97959D;
    font-family: 'Lexend Variable', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.08em;
    text-align: left;

}

.usage_selectedPill__oCfb6 {
    background-color: #5863B4;
    color: #FFFFFF !important;
}

.usage_bigContainer__DzJXj {
    flex-basis: 64%;
}

.usage_smallContainer__1XtNP {
    flex-basis: 32%;
    padding: 20px;
}

.usage_pillContainer__tFtzb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.usage_pill__jWay9 {
    border: 1px solid #5863B4;
    border-radius: 5px;
    color: #FFFFFF;
    background-color: #5863B4;
    padding: 2px 16px;
    -webkit-user-select: none;
            user-select: none;
    font-size: 12px;
    font-family: Poppins, sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/screens/dashboard/components/usage/index.module.css"],"names":[],"mappings":"AAAA;;IAEI,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,0CAA0C;IAC1C,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;;AAEpB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,yBAAyB;IACzB,iBAAiB;IACjB,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;IACf,gCAAgC;AACpC","sourcesContent":[".bigContainer,\n.smallContainer {\n    background-color: #FFFFFF;\n    border-radius: 10px;\n    margin-top: 20px;\n    color: #97959D;\n    font-family: 'Lexend Variable', sans-serif;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 22px;\n    letter-spacing: 0.08em;\n    text-align: left;\n\n}\n\n.selectedPill {\n    background-color: #5863B4;\n    color: #FFFFFF !important;\n}\n\n.bigContainer {\n    flex-basis: 64%;\n}\n\n.smallContainer {\n    flex-basis: 32%;\n    padding: 20px;\n}\n\n.pillContainer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.pill {\n    border: 1px solid #5863B4;\n    border-radius: 5px;\n    color: #FFFFFF;\n    background-color: #5863B4;\n    padding: 2px 16px;\n    user-select: none;\n    font-size: 12px;\n    font-family: Poppins, sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bigContainer": `usage_bigContainer__DzJXj`,
	"smallContainer": `usage_smallContainer__1XtNP`,
	"selectedPill": `usage_selectedPill__oCfb6`,
	"pillContainer": `usage_pillContainer__tFtzb`,
	"pill": `usage_pill__jWay9`
};
export default ___CSS_LOADER_EXPORT___;
