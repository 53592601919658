// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.daterange_container__23lbV {

}`, "",{"version":3,"sources":["webpack://./src/components/ajonjolib/inputs/daterange/index.module.css"],"names":[],"mappings":"AAAA;;AAEA","sourcesContent":[".container {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `daterange_container__23lbV`
};
export default ___CSS_LOADER_EXPORT___;
