// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date_container__dn2o0 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    color: #737373;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    line-height: 29px;
    text-align: left;
    font-size: 13px;
}

.date_container__dn2o0 input[type="date"] {
    width: 100%;
    padding: 10px;
    border: none;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 29px;
    text-align: left;
    color: #737373;
    border-radius: 10px;
}

.date_container__dn2o0 input[type="date"]:focus {
    outline: none;
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ajonjolib/inputs/date/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,gCAAgC;IAChC,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;IACZ,gCAAgC;IAChC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    height: 100%;\n    background-color: #FFFFFF;\n    border-radius: 10px;\n    color: #737373;\n    font-family: Poppins, sans-serif;\n    font-weight: 400;\n    line-height: 29px;\n    text-align: left;\n    font-size: 13px;\n}\n\n.container input[type=\"date\"] {\n    width: 100%;\n    padding: 10px;\n    border: none;\n    font-family: Poppins, sans-serif;\n    font-size: 13px;\n    font-weight: 400;\n    line-height: 29px;\n    text-align: left;\n    color: #737373;\n    border-radius: 10px;\n}\n\n.container input[type=\"date\"]:focus {\n    outline: none;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `date_container__dn2o0`
};
export default ___CSS_LOADER_EXPORT___;
