import React, {useEffect, useState} from "react";
import {Label, Select, Text} from "../../../components/ajonjolib/inputs/ajonjolinput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import api from "../../../api";

export default function EmployeesCreate() {
    const [form, setForm] = useState({});
    const [companies, setCompanies] = useState([]);
    const [projects, setProjects] = useState([]);
    const [guards, setGuards] = useState([]);
    const [shifts, setShifts] = useState([]);
    const [devices, setDevices] = useState([]);
    const [groupProject, setGroupProject] = useState([]);

    useEffect(() => {
        api.get('subenterprises/').then((response) => {
            if(response?.status !== 200) return;
            setCompanies(response.data);
        })

        api.get('projects/').then((response) => {
            if(response?.status !== 200) return;
            setProjects(response.data);
        })

        api.get('guards/').then((response) => {
            if(response?.status !== 200) return;
            setGuards(response.data);
        })

        api.get('turns/').then((response) => {
            if(response?.status !== 200) return;
            setShifts(response.data);
        });

        api.get('devices/').then((response) => {
            if(response?.status !== 200) return;
            setDevices(response.data);
        });

        api.get('project-groups/').then((response) => {
            if(response?.status !== 200) return;
            setGroupProject(response.data);
        });
    }, []);

    const submit = () => {
        api.post('employees/', form).then((response) => {
            if(response?.status !== 201) return;
            window.location.reload();
        });
    }

    return (
        <>
            <div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>Apellidos</Label>
                        <Text value={form['vApellidos']} onChange={(val) => {
                            setForm({...form, vApellidos: val});
                        }}/>
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>Nombres</Label>
                        <Text value={form['vNombres']} onChange={(val) => {
                            setForm({...form, vNombres: val});
                        }}/>
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>ID</Label>
                        <Text value={form['vCodEmpleado']} onChange={(val) => {
                            setForm({...form, vCodEmpleado: val});
                        }}/>
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>Empresa</Label>
                        <Select value={form['subenterprise']} onChange={(val) => {
                            setForm({...form, subenterprise: val[0]});
                        }} options={companies?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })}/>
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>Grupo de Proyecto</Label>
                        <Select value={form['projectGroup']} onChange={(val) => {
                            setForm({...form, projectGroup: val[0]});
                        }} options={groupProject?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })}/>
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>Proyecto</Label>
                        <Select options={projects?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} value={form['project']} onChange={(val) => {
                            setForm({...form, project: val[0]});
                        }}/>
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>Guardia</Label>
                        <Select value={form['guard']} onChange={(val) => {
                            setForm({...form, guard: val[0]})
                        }} options={guards?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })}/>
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>Turno</Label>
                        <Select value={form['turn']} onChange={(val) => {
                            setForm({...form, turn: val[0]})
                        }} options={shifts?.map((x) => {
                            return {
                                name: x.isDay ? "Día" : "Noche",
                                value: x.id
                            }
                        })}/>
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>Pulsera</Label>
                        <Select value={form['device']} options={devices?.map((x) => {
                            return {
                                name: x.serial,
                                value: x.id
                            }
                        })} onChange={(val) => {
                            setForm({...form, device: val[0]})
                        }}/>
                    </div>
                </div>
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'}/>
                <div style={{color: '#5863B4'}}>Guardar</div>
            </div>
        </>
    )
}