import React, { useContext, useEffect, useState } from "react";
import Header from "../../dashboard/components/header";
import HeaderCreate from "../../../components/header_create";
import MainContext from "../../../context";
import Table from "../../../components/ajonjolib/table/table";
import { Button } from "../../../components/ajonjolib/inputs/ajonjolinput";
import BandsModal from "../company/modals/bands";
import ExportModal from "../company/modals/export";
import { eventBus, openModal } from "../../../components/ajonjolib/toasts/toast/toast";
import ImportModal from "../company/modals/import";
import BandsCreate from "./create";
import api from "../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function Bands() {
    const [rows, setRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [openCreate, setOpenCreate] = useState(false);

    const { setSettings } = useContext(MainContext);
    useEffect(() => {
        document.title = "Sleeptech - Pulseras";
        setSettings((prev) => {
            return {
                ...prev, sidebar: 3, submenu: 5
            }
        });

        api.get('devices/').then(response => {
            setRows(response.data);
        })
    }, [setSettings]);

    const cols = [
        { title: 'N° Serie', key: 'serial' },
        { title: 'Marca', key: 'model.name' },
        { title: 'Modelo', key: 'model.model' },
        { title: 'MAC de Dispositivo', key: 'mac' },
        { title: 'Llave de Dispositivo', key: 'key' },
        { title: 'Grupo de Proyecto', key: 'project_group.name' },
        { title: 'Proyecto', key: 'employee_device.project.name' },
        {
            title: 'Usuario Asignado', key: 'employee_device', header: (title) => {
                return (
                    <div className={'d-flex justify-content-center flex-column align-items-center'}>
                        <div>{title}</div>
                        <div className={'d-flex justify-content-center align-items-center w-100'} style={{ color: '#FFF' }}>
                            <div className={'d-flex justify-content-center align-items-center me-3'} style={{ backgroundColor: '#5863B4', borderRadius: '5px 5px 0 0', width: '80px', height: '23px', fontSize: '11px' }}>ID</div>
                            <div className={'d-flex justify-content-center align-items-center'} style={{ backgroundColor: '#5863B4', borderRadius: '5px 5px 0 0', width: '205px', height: '23px', fontSize: '11px' }}>Apellidos y Nombres</div>
                        </div>
                    </div>
                )
            }, code: (row, data) => {
                return (
                    <div className={'d-flex justify-content-center align-items-center w-100'}>
                        <div className={'me-3'}>{row?.employee_device?.vCodEmpleado}</div>
                        <div className={'d-flex justify-content-center align-items-center w-100'}>
                            <div>{row?.employee_device?.vApellidos} {row?.employee_device?.vNombres}</div>
                        </div>
                    </div>
                )
            }
        },
        {
            title: 'Batería', key: 'battery_level', code: (row, data) => {
                if (!data) data = 0;
                const percentage = parseInt(data);
                const battery_id = percentage > 70 ? 1 : percentage > 50 ? 2 : percentage > 20 ? 3 : 4;
                return (
                    <div className={'d-flex align-items-center'}>
                        <img alt={'battery'} src={`/icons/battery/${battery_id}.png`} width={'14px'} />
                        <div className={'ps-2'}>{data}%</div>
                    </div>
                )
            }
        },
        {
            title: 'Acciones', key: 'actions', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faEdit} color={'#5863B4'} size={'lg'} role={'button'} onClick={() => {
                            eventBus.emit('bands_modal_open', row);
                        }} />
                    </div>
                )
            }
        }
    ];

    const onSearch = (text) => {
        setSearchText(text);
    }

    const filteredRows = rows.filter((row) => {
        const text = searchText.toLowerCase();
        return row.serial.toLowerCase().includes(text) ||
            row.model.name.toLowerCase().includes(text) ||
            row.model.model.toLowerCase().includes(text) ||
            row.mac.toLowerCase().includes(text) ||
            row.key.toLowerCase().includes(text) ||
            row.project_group?.name?.toLowerCase()?.includes(text) ||
            row.employee_device?.vCodEmpleado.toLowerCase()?.includes(text) ||
            row.employee_device?.vApellidos?.toLowerCase()?.includes(text) ||
            row.employee_device?.vNombres?.toLowerCase()?.includes(text) ||
            row.battery_level?.toString().toLowerCase().includes(text);
    }
    );

    return (
        <div>
            <BandsModal />
            <ExportModal title={'Master de Pulseras'} />
            <ImportModal title={'Master de Pulseras'} />
            <Header title={'Master de Pulseras'}>
                <>
                    <div>
                        <div className={'searchBox'}>
                            <div>
                                <img src={'/icons/search.png'} alt={'search'} width={'auto'} height={'24px'} />
                            </div>
                            <input type={'text'}
                                placeholder={'Buscar...'}
                                onChange={(e) => {
                                    onSearch(e.target.value);
                                }
                                }
                            />
                        </div>
                    </div>
                    <div className={'d-flex align-items-center'}>
                        <Button variant={'primary'} name={'Importar'} icon={'/icons/import.png'} onSubmit={() => {
                            openModal('import_modal')
                        }} />
                        <div className={'ms-3'} />
                        <Button variant={'primary'} name={'Exportar'} icon={'/icons/export.png'} onSubmit={() => {
                            openModal('export_modal')
                        }} />
                    </div>
                </>
            </Header>
            <HeaderCreate name={'Lista de Pulseras'} width={'931px'}
                open={openCreate} setOpen={setOpenCreate}>
                <BandsCreate onSuccess={() => {
                    toast.success('Dispositivo creado correctamente');
                    setOpenCreate(false);
                    api.get('devices/').then(response => {
                        setRows(response.data);
                    })
                }} />
            </HeaderCreate>
            <div className={'mb-3'} />
            <Table cols={cols} rows={filteredRows} />
            <ToastContainer />
        </div>
    )
}