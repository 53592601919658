import React, {useState} from "react";
import {Label, Select, Text, TextArea} from "../../../../components/ajonjolib/inputs/ajonjolinput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import TimePicker from "../../../../components/ajonjolib/inputs/time";
import styles from "../modals/modals.module.css";


function DateSelect({form, setForm}) {
    const dates = [
        "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"
    ];
    return (
        <div className={styles.dateContainer}>
            {dates.map((date, index) => (
                <div key={index} className={styles.dateItem}>
                    <div>{date}</div>
                </div>
            ))}
        </div>
    )
}

function CovidSelect({form, setForm}) {
    const options = [
        "Cargo", "Alerta COVID"
    ];

    return (
        <div className={styles.dateContainer}>
            {options.map((date, index) => (
                <div key={index} className={styles.dateItem}>
                    <div>{date}</div>
                </div>
            ))}
        </div>
    )
}

export default function EmailCreate() {
    const [form, setForm] = useState({});
    return (
        <>
            <div>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{flexBasis: '30%'}}>
                        <Label>Nivel de Configuración</Label>
                        <Select/>
                    </div>

                    <div style={{flexBasis: '30%'}}>
                        <Label>Grupo de Proyecto</Label>
                        <Select/>
                    </div>

                    <div style={{flexBasis: '30%'}}>
                        <Label>Turno</Label>
                        <Select/>
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{flexBasis: '30%'}}>
                        <Label>Nivel de Agrupación</Label>
                        <Select/>
                    </div>

                    <div style={{flexBasis: '60%'}}>
                        <Label>Asunto</Label>
                        <Text/>
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>Destinatarios</Label>
                        <TextArea/>
                    </div>
                </div>

                <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>Días de Envío de Reporte</Label>
                        <DateSelect form={form} setForm={setForm}/>
                    </div>
                </div>

                <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                    <div style={{flexBasis: '50%'}}>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <div>
                                <Label>Primer Envío</Label>
                                <TimePicker/>
                            </div>

                            <div>
                                <Label>Segundo Envío</Label>
                                <TimePicker/>
                            </div>

                            <div>
                                <Label>Tercer Envío</Label>
                                <TimePicker/>
                            </div>
                        </div>
                    </div>
                    <div style={{flexBasis: '45%'}}>
                        <Label>Mostrar</Label>
                        <CovidSelect form={form} setForm={setForm}/>
                    </div>
                </div>
            </div>

            <div className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'}/>
                <div style={{color: '#5863B4'}}>Guardar</div>
            </div>
        </>
    )
}