// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea_container__-pLQn {
    width: 100%;
}

.textarea_container__-pLQn>textarea {
    background-color: #F4F6FB;
    border: 1px solid #E2E5F4;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ajonjolib/inputs/textarea/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,kCAAkC;IAClC,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":[".container {\n    width: 100%;\n}\n\n.container>textarea {\n    background-color: #F4F6FB;\n    border: 1px solid #E2E5F4;\n    border-radius: 10px;\n    width: 100%;\n    padding: 10px;\n    font-family: 'Poppins', sans-serif;\n    font-size: 13px;\n    font-weight: 400;\n    text-align: left;\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `textarea_container__-pLQn`
};
export default ___CSS_LOADER_EXPORT___;
