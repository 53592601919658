import { createContext, useContext, useEffect, useState } from "react";
import { currentDateStr } from "./utils/date";

const FiltersContext = createContext(null);

function FiltersProvider({ children }) {
    const [filters, setFilters] = useState({});

    useEffect(() => {
        setFilters({
            ...filters,
            // Set date as now with %Y-%m-%d format
            date: Date.now(),
            start_date: Date.now(),
            end_date: Date.now(),
        });
    }, []);

    return (
        <FiltersContext.Provider value={[filters, setFilters]}>
            {children}
        </FiltersContext.Provider>
    )
}

export { FiltersContext, FiltersProvider };