// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch_container__ggOdL {
    display: flex;
    justify-content: start;
    width: 100%;
    border-radius: 6px;
    position: relative;
    align-items: center;
}

.switch_switch__P3v6d {
    width: 82px;
    height: 36px;
    position: relative;
    display: inline-block;
    padding: 8px;
    border-radius: 200px;
    background-color: #49546E;
    cursor: pointer;
    transition: background-color 0.3s ease;
    -webkit-user-select: none;
            user-select: none;
}

.switch_toggle__y27WO {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 20px;
    top: 3.5px;
    width: 29px;
    height: 29px;
    transform: translateX(-50%);
    background-color: #FFFFFF;

    border-radius: 50%;
    transition: transform 0.3s ease;
    box-shadow: rgba(0, 0, 0, 25%) 0 3px 3px 0;
}

.switch_switch__P3v6d.switch_active__jFBBo .switch_toggle__y27WO {
    transform: translate(28px, 0);
    box-shadow: rgba(0, 0, 0, 25%) 0 -3px 3px 0;
    background-color: #88D36E;
}

.switch_background__QQMfB {
    background-color: #ECF0F8;
    color: #8A8A8A;
    font-weight: 400;
    font-family: 'Lexend Variable', sans-serif;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    border-radius: 18px;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ajonjolib/switch/switch.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;IACZ,oBAAoB;IACpB,yBAAyB;IACzB,eAAe;IACf,sCAAsC;IACtC,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,yBAAyB;;IAEzB,kBAAkB;IAClB,+BAA+B;IAC/B,0CAA0C;AAC9C;;AAEA;IACI,6BAA6B;IAC7B,2CAA2C;IAC3C,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,0CAA0C;IAC1C,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".container {\n    display: flex;\n    justify-content: start;\n    width: 100%;\n    border-radius: 6px;\n    position: relative;\n    align-items: center;\n}\n\n.switch {\n    width: 82px;\n    height: 36px;\n    position: relative;\n    display: inline-block;\n    padding: 8px;\n    border-radius: 200px;\n    background-color: #49546E;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n    user-select: none;\n}\n\n.toggle {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    position: absolute;\n    left: 20px;\n    top: 3.5px;\n    width: 29px;\n    height: 29px;\n    transform: translateX(-50%);\n    background-color: #FFFFFF;\n\n    border-radius: 50%;\n    transition: transform 0.3s ease;\n    box-shadow: rgba(0, 0, 0, 25%) 0 3px 3px 0;\n}\n\n.switch.active .toggle {\n    transform: translate(28px, 0);\n    box-shadow: rgba(0, 0, 0, 25%) 0 -3px 3px 0;\n    background-color: #88D36E;\n}\n\n.background {\n    background-color: #ECF0F8;\n    color: #8A8A8A;\n    font-weight: 400;\n    font-family: 'Lexend Variable', sans-serif;\n    font-size: 14px;\n    line-height: 15px;\n    display: flex;\n    border-radius: 18px;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `switch_container__ggOdL`,
	"switch": `switch_switch__P3v6d`,
	"toggle": `switch_toggle__y27WO`,
	"active": `switch_active__jFBBo`,
	"background": `switch_background__QQMfB`
};
export default ___CSS_LOADER_EXPORT___;
