import React, { useState } from "react";
import styles from './index.module.css';
import { Text } from "../../components/ajonjolib/inputs/ajonjolinput";
import api from "../../api";
import axios from "axios";

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const submit = () => {
        axios.post('https://stracon.sleeptech-backend.stracontech.com/auth/login', { username, password }).then((response) => {
            localStorage.clear();
            localStorage.setItem('access_token', response.data.token);
            localStorage.setItem('refresh_token', response.data.token);
            localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
            api.defaults.headers.common['Authorization'] = `Token ${response.data.token}`;
            window.location.replace('/');
        });
    }

    return (
        <div className={styles.container}>
            <img className={styles.logo} alt={'stracon'} src={'/branding/stracontech_white.png'} width={'223px'} />
            <img className={styles.background} src={'/login_background.png'} alt={'background'} />

            <div className={styles.loginContainer}>
                <div className={styles.title}>
                    <div style={{ fontWeight: 700 }}>Login</div>
                    <div />
                    <div style={{ color: '#9F95F6' }}>MONITOREO DE SUEÑO</div>
                </div>

                <div>
                    <div className={'mb-5'}>
                        <div className={styles.label}>
                            <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.58239 0H18.4179C19.2869 0 20 0.711276 20 1.57754V11.4225C20 12.289 19.2869 13 18.4179 13H1.58239C0.711354 13 0 12.2887 0 11.4225V1.57754C0 1.14491 0.1784 0.750584 0.465337 0.463984L0.464339 0.462989C0.751026 0.177384 1.14675 0 1.58239 0ZM18.0246 11.9904L11.7851 7.13788C11.215 7.58122 10.7896 7.89071 10.0001 7.89071C9.21067 7.89071 8.78501 7.58147 8.21488 7.13788L1.97562 11.9904H18.0246ZM12.6055 6.5L18.9862 11.4625V1.53749L12.6055 6.5ZM1.01401 11.4625L7.39474 6.5L1.01401 1.53749V11.4625ZM1.97562 1.00957L9.14281 6.58359C9.65231 6.97991 10.3477 6.97991 10.8572 6.58359L18.0244 1.00957H1.97562Z" fill="#9F95F6" /></svg>
                            <div className={'ps-3'}>E-mail</div>
                        </div>

                        <Text placeholder={'E-mail'} value={username} onChange={(value) => {
                            setUsername(value);
                        }} />
                    </div>

                    <div>
                        <div className={styles.label}>
                            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3333 8.66667V5.66667C13.3333 2.53333 10.8 0 7.66667 0C4.53333 0 2 2.53333 2 5.66667V8.66667H0V20H15.3333V8.66667H13.3333ZM3.33333 5.66667C3.33333 3.26667 5.26667 1.33333 7.66667 1.33333C10.0667 1.33333 12 3.26667 12 5.66667V8.66667H3.33333V5.66667ZM14 18.6667H1.33333V10H14V18.6667Z" fill="#9F95F6" /><path d="M6.99967 14.4667V16.6667H8.33301V14.4667C8.73301 14.2333 8.99967 13.8 8.99967 13.3333C8.99967 12.6 8.39967 12 7.66634 12C6.93301 12 6.33301 12.6 6.33301 13.3333C6.33301 13.8333 6.59967 14.2667 6.99967 14.4667Z" fill="#9F95F6" /></svg>
                            <div className={'ps-3'}>Contraseña</div>
                        </div>

                        <Text placeholder={'******'} type={'password'} value={password} onChange={(value) => {
                            setPassword(value);
                        }} />
                    </div>

                    <div className={'d-flex justify-content-center align-items-center mt-5'}>
                        <div className={styles.loginButton} onClick={submit}>
                            <div>Login</div>
                        </div>
                    </div>

                    <div className={styles.forgot} onClick={() => {
                        window.location.replace('/password_reset');
                    }}>¿Olvidaste tu contraseña?</div>
                </div>
            </div>
        </div>
    )
}