import React, {useEffect, useState} from "react";
import Table from "../../../../components/ajonjolib/table/table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";
import {eventBus} from "../../../../components/ajonjolib/toasts/toast/toast";

export default function Guard({company}) {
    const [rows, setRows] = useState([]);

    const cols = [
        {title: 'Estado', key: 'active', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <div className={'me-2'} style={{width: '16px', height: '16px', borderRadius: '50%', backgroundColor: data ? '#00C853' : '#EAEAEC'}}/>
                        <div>{data}</div>
                    </div>
                )
            }},
        {title: 'Grupo de Proyecto', key: 'project_group.name'},
        {title: 'Proyecto', key: 'project.name'},
        {title: 'Guardia', key: 'name'},
        {title: 'Total de Empleados', key: 'employees_count'},
        {title: 'Rango de Fechas', key: 'read', header: (title) => {
                return (
                    <div className={'d-flex justify-content-center flex-column align-items-center'}>
                        <div>{title}</div>
                        <div className={'d-flex justify-content-center align-items-center w-100'} style={{color: '#FFF'}}>
                            <div className={'d-flex justify-content-center align-items-center me-3'} style={{backgroundColor: '#5863B4', borderRadius: '5px 5px 0 0', width: '80px', height: '23px', fontSize: '11px'}}>Desde</div>
                            <div className={'d-flex justify-content-center align-items-center'} style={{backgroundColor: '#5863B4', borderRadius: '5px 5px 0 0', width: '80px', height: '23px', fontSize: '11px'}}>Hasta</div>
                        </div>
                    </div>
                )}, code: (row, data) => {
                return (
                    <div className={'d-flex justify-content-between align-items-center w-100'}>
                        <div className={'me-3'}>{row.start_date}</div>
                        <div>{row.end_date}</div>
                    </div>
                )
            }},
        {title: 'Acciones', key: 'actions', code: (row) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faEdit} color={'#5863B4'} size={'lg'} role={'button'} onClick={() => {
                            eventBus.emit('guard_modal_open', row);
                        }}/>
                    </div>
                )
            }}
    ];

    useEffect(() => {
        api.get(`guards/?subenterprise=${company?.id}`).then((response) => {
            setRows(response.data);
        });
    }, []);

    return (
        <div>
            <Table model={'guards'} cols={cols} rows={rows}/>
        </div>
    )
}