import React, {useEffect, useState} from "react";
import { Label, Select } from "../../../components/ajonjolib/inputs/ajonjolinput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import TimePicker from "../../../components/ajonjolib/inputs/time";
import api from "../../../api";

export default function ShiftsCreate() {
    const [form, setForm] = useState({});
    const [projects, setProjects] = useState([]);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        api.get('projects/').then((response) => {
            setProjects(response.data);
        });

        api.get('subenterprises/').then((response) => {
            setCompanies(response.data);
        });
    }, []);

    const submit = () => {
        api.post('turns/', form).then(() => {
            alert('Guardado correctamente');
        });
    }

    return (
        <>
            <div>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>Empresa</Label>
                        <Select value={form['subenterprise']} options={companies?.map((x) => {
                            return {
                                name: x.name,
                                value: x.id
                            }
                        })} onChange={(val) => {
                            setForm({...form, subenterprise: val[0]});
                        }}/>
                    </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>Proyecto</Label>
                        <Select options={projects?.map((x) => {return {
                            name: x.name,
                            value: x.id
                        }})} value={form['project']} onChange={(val) => {
                            setForm({...form, project: val[0]});
                        }}/>
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>Turno</Label>
                        <Select value={form['isDay']} options={[{name: 'Día', value: 1}, {name: "Noche", value: 0}]} onChange={(val) => {
                            setForm({...form, isDay: val[0] === 1});
                        }}/>
                    </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mb-4'}>
                    <div style={{flexBasis: '100%'}}>
                        <Label>Horario Laboral</Label>
                        <div className={'d-flex align-items-center justify-content-between'}>
                            <TimePicker value={form['laboral_time_start']} onChange={(val) => {
                                setForm({...form, laboral_time_start: val});
                            }}/>
                            <div>-</div>
                            <TimePicker value={form['laboral_time_end']} onChange={(val) => {
                                setForm({...form, laboral_time_end: val});
                            }}/>
                        </div>
                    </div>
                </div>
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'}/>
                <div style={{color: '#5863B4'}}>Guardar</div>
            </div>
        </>
    )
}