import React, { useState } from "react";
import styles from "../../screens/dashboard/components/risk/index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function HeaderCreate({ name, children, width, height,
    open, setOpen,
}) {
    const [localOpen, setLocalOpen] = useState(false);
    let openCreate = open;
    let setOpenCreate = setOpen;
    if (!open && !setOpen) {
        openCreate = localOpen;
        setOpenCreate = setLocalOpen;
    }
    return (
        <div className={'d-flex justify-content-between align-items-center'}>
            <div className={styles.dropdown} style={{ cursor: 'default' }}>
                <div>{name}</div>
            </div>
            <div className={styles.createButton}>
                <div className={styles.createButtonInner} onClick={() => setOpenCreate((prev) => !prev)}>
                    <FontAwesomeIcon className={'me-3'} icon={faPlus} color={'#5863B4'} size={'xl'} />
                    <div>Nuevo</div>
                </div>
                {openCreate &&
                    <div className={`${styles.createPopup}`} style={{
                        width: width ? width : '100%', height: height ? height : 'auto',
                        borderRadius: width ? '9px 0 9px 9px' : '0 0 9px 9px',
                    }}>
                        {children}
                    </div>
                }
            </div>
        </div>
    )
}