import styles from './index.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

export default function Header({title, subtitle, children}) {
    return (
        <div className={styles.container}>
            <div className={'d-flex align-items-center'}>
                <div className={styles.title}>{title}</div>
                {subtitle &&
                    <>
                        <FontAwesomeIcon className={'px-2'} icon={faChevronRight} color={'#5863B4'} size={'sm'}/>
                        <div className={styles.subtitle}>{subtitle}</div>
                    </>
                }
            </div>
            {children}
        </div>
    )
}