import React, {useEffect, useState} from "react";
import {eventBus} from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import styles from '../modals.module.css';
import GenericGraph from "../../../../components/ajonjolib/graphs/generic_graph";

export default function HeartModal() {
    const [data, setData] = useState([]);
    const [minMax, setMinMax] = useState([0, 0]);
    const [isOpen, setIsOpen] = useState(false);
    const [date, setDate] = useState(null);
    const [turn, setTurn] = useState(null);

    useEffect(() => {
        eventBus.subscribe('heart_modal_open', (rec_data) => {
            setDate(rec_data.date);
            const employee_data = rec_data.employee;
            const turn = employee_data?.turn;
            const isDay = turn?.isDay ? 'DIA' : 'NOCHE';
            const project = employee_data?.project;
            const laboralTimeStart = turn?.laboral_time_start ?? '';
            const laboralTimeEnd = turn?.laboral_time_end ?? '';
            const turn_name = isDay + ' ' + (project?.name ?? 'PROYECTO DESCONOCIDO') + ' [' + laboralTimeStart + ' - ' + laboralTimeEnd + ']';
            setTurn(turn_name);
            const samples = rec_data.activity_samples;
            if(samples.length <= 0) return;
            let graph_data = [];
            let min = 999;
            let max = 0;
            for(let i = 0; i < samples.length; i++) {
                const timestamp = samples[i]?.timestamp;
                const heart_rate = samples[i]?.heart_rate;
                if(heart_rate !== 0) {
                    if(heart_rate < min) min = heart_rate;
                    if(heart_rate > max) max = heart_rate;
                    const name = new Date(timestamp * 1000).toTimeString().slice(0, 5);
                    graph_data.push({
                        name: name,
                        timestamp: timestamp,
                        fq: heart_rate
                    });
                }
            }
            console.log(graph_data);
            setMinMax([min, max]);
            setData(graph_data);
            setIsOpen(true);
        });
        eventBus.subscribe('heart_modal_close', (data) => {setIsOpen(false)});
    }, []);

    const today = () => {
        const date2 = new Date(date);
        date2.setDate(date2.getDate() + 1);
        return date2.toLocaleDateString('en-DE');
    }

    return (
        <>
            {isOpen &&
                <Modal style={{width: '1036px'}} padding={'0'} title={'Admin Admin'} desc={today()}  close={() => setIsOpen(false)}>
                    <div className={styles.container}>
                        <div className={'d-flex align-items-center'}>
                            <div style={{fontWeight: 600}} className={'pe-2'}>Turno:</div>
                            <div>{turn} Descanso [ - 04:30]</div>
                        </div>

                        <div className={styles.content}>
                            <div className={'d-flex align-items-center justify-content-between'}>
                                <div>Frecuencia Cardíaca</div>
                                <div className={'d-flex align-items-center justify-content-between'}>
                                    <div className={styles.heartRate}>
                                        <div className={'d-flex justify-content-center align-items-center flex-column me-4 ms-2'}>
                                            <img alt={'heart'} src={'/icons/white_heart.png'} width={'21px'}/>
                                            <div style={{fontSize: '11px', lineHeight: '14px'}}>Max</div>
                                        </div>
                                        <div>{minMax[1]}</div>
                                    </div>
                                    <div className={styles.heartRate}>
                                        <div className={'d-flex justify-content-center align-items-center flex-column me-4 ms-2'}>
                                            <img alt={'heart'} src={'/icons/white_heart.png'} width={'21px'}/>
                                            <div style={{fontSize: '11px', lineHeight: '14px'}}>Min</div>
                                        </div>
                                        <div>{minMax[0]}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '100%'}}>
                                <GenericGraph legend={false} title={'Frecuencia'} unit={'H'} data={data} format={[
                                    {hex: '#F66565', name: 'Frecuencia', key: 'fq'},
                                ]}/>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}