// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.evolutive_bigContainer__ayblI,
.evolutive_smallContainer__cGCaL {
    background-color: #FFFFFF;
    border-radius: 10px;
    margin-top: 20px;
    color: #97959D;
    font-family: 'Lexend Variable', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.08em;
    text-align: left;

}

.evolutive_bigContainer__ayblI {
    flex-basis: 64%;
}

.evolutive_smallContainer__cGCaL {
    flex-basis: 32%;
    padding: 20px;
}


.evolutive_graphContainer__MtD5t>div:first-of-type {
    font-family: 'Lexend Variable', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.08em;
    text-align: left;
    padding: 22px;
}

.evolutive_graphContainer__MtD5t {
    background-color: #FFFFFF;
    border-radius: 10px;
    margin-top: 20px;
    color: #97959D;
    width: 100%;
    padding-right: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/dashboard/components/evolutive/index.module.css"],"names":[],"mappings":"AAAA;;IAEI,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,0CAA0C;IAC1C,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;;AAEpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,aAAa;AACjB;;;AAGA;IACI,0CAA0C;IAC1C,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".bigContainer,\n.smallContainer {\n    background-color: #FFFFFF;\n    border-radius: 10px;\n    margin-top: 20px;\n    color: #97959D;\n    font-family: 'Lexend Variable', sans-serif;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 22px;\n    letter-spacing: 0.08em;\n    text-align: left;\n\n}\n\n.bigContainer {\n    flex-basis: 64%;\n}\n\n.smallContainer {\n    flex-basis: 32%;\n    padding: 20px;\n}\n\n\n.graphContainer>div:first-of-type {\n    font-family: 'Lexend Variable', sans-serif;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 29px;\n    letter-spacing: 0.08em;\n    text-align: left;\n    padding: 22px;\n}\n\n.graphContainer {\n    background-color: #FFFFFF;\n    border-radius: 10px;\n    margin-top: 20px;\n    color: #97959D;\n    width: 100%;\n    padding-right: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bigContainer": `evolutive_bigContainer__ayblI`,
	"smallContainer": `evolutive_smallContainer__cGCaL`,
	"graphContainer": `evolutive_graphContainer__MtD5t`
};
export default ___CSS_LOADER_EXPORT___;
