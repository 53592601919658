import React, { useContext, useEffect, useState } from "react";
import styles from './index.module.css';
import { Button, Select, Date } from "../../../../components/ajonjolib/inputs/ajonjolinput";
import MainContext from "../../../../context";
import api from "../../../../api";
import DashboardFilters from "../../../../components/filters/sections/dashboard";
import ResultsFilters from "../../../../components/filters/sections/results";
import DetailFilters from "../../../../components/filters/sections/detail";

export default function Filters({ filters, setFilters, type }) {
    const { setSettings, settings } = useContext(MainContext);
    const [companies, setCompanies] = useState([]);
    const [projectGroups, setProjectGroups] = useState([]);
    const [projects, setProjects] = useState([]);
    const [guards, setGuards] = useState([]);
    const [shifts, setShifts] = useState([]);

    useEffect(() => {
        if (!filters['company']) return;
        api.get(`project-groups/?subenterprise=${filters['company']}`).then((response) => {
            setProjectGroups(response.data);
        });
    }, [filters['company']]);

    useEffect(() => {
        if (!filters['project_group']) return;
        api.get(`projects/?project_group=${filters['project_group']}`).then((response) => {
            setProjects(response.data);
        });
    }, [filters['project_group']]);

    useEffect(() => {
        if (!filters['project']) return;
        api.get(`guards/?project=${filters['project']}&project_group=${filters['project_group']}`).then((response) => {
            setGuards(response.data);
        });

        api.get(`turns/?project=${filters['project']}`).then((response) => {
            setShifts(response.data);
        })
    }, [filters['project']]);

    useEffect(() => {
        api.get('subenterprises/').then((response) => {
            setCompanies(response.data);
        });
    }, []);

    const mapFilters = () => {
        if (type === 'dashboard') return (<DashboardFilters filters={filters} setFilters={setFilters} guards={guards} projectGroups={projectGroups} projects={projects} companies={companies} />);
        else if (type === 'results') return (<ResultsFilters filters={filters} setFilters={setFilters} guards={guards} projectGroups={projectGroups} projects={projects} companies={companies} />)
        else if (type === 'detailed') return <DetailFilters filters={filters} setFilters={setFilters} projectGroups={projectGroups} projects={projects} companies={companies} shifts={shifts} />
    }

    return (
        <div className={styles.container} style={{
            height: settings.filters ? '100%' : '77px',
        }}>
            <div className={styles.title} onClick={() => {
                setSettings({
                    ...settings,
                    filters: !settings.filters
                });
            }}>
                <img src={'/icons/filters.png'} alt={'filters'} style={{ width: '31px', height: '23px' }} />
                <div className={'ps-4'}>Filtros</div>
            </div>

            {mapFilters()}
        </div>
    )
}