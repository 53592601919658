import React, {useEffect, useState} from "react";
import {eventBus} from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import styles from "./modals.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {Label, Select, Text} from "../../../../components/ajonjolib/inputs/ajonjolinput";
import Switch from "../../../../components/ajonjolib/switch";
import api from "../../../../api";

export default function EmployeesModal() {
    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState({});
    const [companies, setCompanies] = useState([]);
    const [projects, setProjects] = useState([]);
    const [guards, setGuards] = useState([]);
    const [shifts, setShifts] = useState([]);
    const [devices, setDevices] = useState([]);
    const [groupProject, setGroupProject] = useState([]);

    useEffect(() => {
        api.get('subenterprises/').then((response) => {
            if(response?.status !== 200) return;
            setCompanies(response.data);
        })

        api.get('projects/').then((response) => {
            if(response?.status !== 200) return;
            setProjects(response.data);
        })

        api.get('guards/').then((response) => {
            if(response?.status !== 200) return;
            setGuards(response.data);
        })

        api.get('turns/').then((response) => {
            if(response?.status !== 200) return;
            setShifts(response.data);
        });

        api.get('devices/').then((response) => {
            if(response?.status !== 200) return;
            setDevices(response.data);
        });

        api.get('project-groups/').then((response) => {
            if(response?.status !== 200) return;
            setGroupProject(response.data);
        });
    }, [isOpen]);

    React.useEffect(() => {
        eventBus.subscribe('employees_modal_open', (rec_data) => {
            setIsOpen(true);
            const copy = {
                ...rec_data,
                subenterprise: rec_data.subenterprise?.id,
                project: rec_data.project?.id,
                device: rec_data.device?.id,
                guard: rec_data.guard?.id,
                turn: rec_data.turn?.id,
                projectGroup: rec_data.projectGroup?.id,
            };
            setForm(copy);
            console.log(copy);
        });
        eventBus.subscribe('employees_modal_close', (data) => {setIsOpen(false)});
    }, []);

    const submit = () => {
        api.put(`employees/${form?.id}/`, form).then((response) => {
            window.location.reload();
        });
    }

    return (
        <div>
            {isOpen &&
                <Modal title={'Master de Empleados'} desc={'Editar'} close={() => setIsOpen(false)}>
                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{flexBasis: '25%'}}>
                            <Label>Estado</Label>
                            <Switch value={form['active']} onChange={(e) => setForm({
                                ...form, active: e
                            })}/>
                        </div>

                        <div style={{flexBasis: '65%'}}>
                            <Label>Empresa</Label>
                            <Select value={form['subenterprise']} onChange={(val) => {
                                setForm({...form, subenterprise: val[0]});
                            }} options={companies?.map((x) => {
                                return {
                                    name: x.name,
                                    value: x.id
                                }
                            })}/>
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{flexBasis: '45%'}}>
                            <Label>Grupo de Proyecto</Label>
                            <Select value={form['projectGroup']} onChange={(val) => {
                                setForm({...form, projectGroup: val[0]});
                            }} options={groupProject?.map((x) => {
                                return {
                                    name: x.name,
                                    value: x.id
                                }
                            })}/>
                        </div>

                        <div style={{flexBasis: '45%'}}>
                            <Label>Proyecto</Label>
                            <Select options={projects?.map((x) => {
                                return {
                                    name: x.name,
                                    value: x.id
                                }
                            })} value={form['project']} onChange={(val) => {
                                setForm({...form, project: val[0]});
                            }}/>
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{flexBasis: '45%'}}>
                            <Label>Apellidos</Label>
                            <Text value={form['vApellidos']} onChange={(val) => {
                                setForm({...form, vApellidos: val});
                            }}/>
                        </div>

                        <div style={{flexBasis: '45%'}}>
                            <Label>Nombres</Label>
                            <Text value={form['vNombres']} onChange={(val) => {
                                setForm({...form, vNombres: val});
                            }}/>
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{flexBasis: '45%'}}>
                            <Label>ID</Label>
                            <Text value={form['vCodEmpleado']} onChange={(val) => {
                                setForm({...form, vCodEmpleado: val});
                            }}/>
                        </div>

                        <div style={{flexBasis: '45%'}}>
                            <Label>Turno</Label>
                            <Select value={form['turn']} onChange={(val) => {
                                setForm({...form, turn: val[0]})
                            }} options={shifts?.map((x) => {
                                return {
                                    name: x.isDay ? "Día" : "Noche",
                                    value: x.id
                                }
                            })}/>
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{flexBasis: '45%'}}>
                            <Label>Pulsera</Label>
                            <Select value={form['device']} options={devices?.map((x) => {
                                return {
                                    name: x.serial,
                                    value: x.id
                                }
                            })} onChange={(val) => {
                                setForm({...form, device: val[0]})
                            }}/>
                        </div>

                        <div style={{flexBasis: '45%'}}>
                            <Label>Guardia</Label>
                            <Select value={form['guard']} onChange={(val) => {
                                setForm({...form, guard: val[0]})
                            }} options={guards?.map((x) => {
                                return {
                                    name: x.name,
                                    value: x.id
                                }
                            })}/>
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                        <div className={styles.button} onClick={submit}>
                            <FontAwesomeIcon icon={faSave} color={'#52A035'}/>
                            <div className={'ps-2'}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}