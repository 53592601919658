import React, {useEffect, useState} from "react";
import Table from "../../../../components/ajonjolib/table/table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";
import {eventBus} from "../../../../components/ajonjolib/toasts/toast/toast";

export default function Group({company}) {
    const [rows, setRows] = useState([]);
    const cols = [
        {title: 'Estado', key: 'active', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <div className={'me-2'} style={{width: '16px', height: '16px', borderRadius: '50%', backgroundColor: data ? '#00C853' : '#EAEAEC'}}/>
                        <div>{data}</div>
                    </div>
                )
            }},
        {title: 'Grupo de Proyecto', key: 'name'},
        {title: 'Servicio', key: 'services', code: (row, data) => {
            return (
                <div>{data.join(', ')}</div>
            )
        }},
        {title: 'Mostrar TTS en Reposo', key: 'show_tts', code: (row, data) => {
            return (
                <div>{data ? 'Sí' : 'No'}</div>
            )
            }},
        {title: 'Acciones', key: 'actions', code: (row, data) => {
                return (
                    <div className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faEdit} color={'#5863B4'} size={'lg'} role={'button'} onClick={() => {
                            eventBus.emit('group_modal_open', row);
                        }}/>
                    </div>
                )
            }}
    ];

    useEffect(() => {
        api.get(`project-groups/?subenterprise=${company?.id}`).then((response) => {
            if(response?.status !== 200) return;
            setRows(response.data);
        });
    }, []);

    return (
        <div>
            <Table model={'project-groups'} cols={cols} rows={rows}/>
        </div>
    )
}