import React, {useEffect, useState} from "react";
import {Label, Select, Text} from "../../../../components/ajonjolib/inputs/ajonjolinput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import api from "../../../../api";

export default function ProjectCreate() {
    const [form, setForm] = useState({vCenBeneficio: "test", vDenominacion: "test"});
    const [projectGroups, setProjectGroups] = useState([]);

    const submit = () => {
        api.post('projects/', form).then((response) => {
            window.location.reload();
        });
    }

    useEffect(() => {
        api.get('project-groups/').then((res) => {
            if (res?.status !== 200) return;
            setProjectGroups(res.data);
        });
    }, []);

    return (
        <>
            <div>
                <Label>Grupo de Proyecto</Label>
                <Select value={form['project_group']} options={projectGroups?.map((x) => {
                    return {
                        value: x.id,
                        name: x.name
                    }
                })} onChange={(val) => {
                    setForm({...form, project_group: val[0]})
                }}/>
                <div className={'mb-4'}/>
                <Label>Zona Horaria</Label>
                <Text value={form['timezone']} onChange={(val) => {
                    setForm({...form, timezone: val})
                }}/>
                <div className={'mb-4'}/>
                <Label>Proyecto</Label>
                <Text value={form['vDefProyecto']} onChange={(val) => {
                    setForm({...form, vDefProyecto: val})
                }}/>
            </div>

            <div onClick={submit} className={'p-2 d-flex align-items-center justify-content-center'} style={{
                backgroundColor: '#CFD4F8', cursor: 'pointer', userSelect: 'none', borderRadius: '9px'
            }}>
                <FontAwesomeIcon className={'me-3'} icon={faSave} color={'#5863B4'}/>
                <div style={{color: '#5863B4'}}>Guardar</div>
            </div>
        </>
    )
}