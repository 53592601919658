import React, {useEffect, useState} from "react";
import {eventBus} from "../../../../components/ajonjolib/toasts/toast/toast";
import Modal from "../../../../components/ajonjolib/modal/modal/modal";
import {Label, Select, Text, TextArea} from "../../../../components/ajonjolib/inputs/ajonjolinput";
import Switch from "../../../../components/ajonjolib/switch";
import styles from "./modals.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import TimePicker from "../../../../components/ajonjolib/inputs/time";

function DateSelect({form, setForm}) {
    const dates = [
        "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"
    ];
    return (
        <div className={styles.dateContainer}>
            {dates.map((date, index) => (
                <div key={index} className={styles.dateItem}>
                    <div>{date}</div>
                </div>
            ))}
        </div>
    )
}

function CovidSelect({form, setForm}) {
    const options = [
        "Cargo", "Alerta COVID"
    ];

    return (
        <div className={styles.dateContainer}>
            {options.map((date, index) => (
                <div key={index} className={styles.dateItem}>
                    <div>{date}</div>
                </div>
            ))}
        </div>
    )
}

export default function EmailModal() {
    const [isOpen, setIsOpen] = React.useState(false);
    const [form, setForm] = useState({});

    useEffect(() => {
        eventBus.subscribe('email_modal_open', (rec_data) => {
            setIsOpen(true);
        });

        eventBus.subscribe('email_modal_close', (data) => {
            setIsOpen(false)
        });
    }, []);

    const submit = () => {

    }

    return (
        <div>
            {isOpen &&
                <Modal style={{width: '66%'}} title={'E-Mail'} desc={'Editar'} close={() => setIsOpen(false)}>
                    <Label>Estado</Label>
                    <Switch/>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{flexBasis: '32%'}}>
                            <Label>Nivel de Configuración</Label>
                            <Select/>
                        </div>

                        <div style={{flexBasis: '32%'}}>
                            <Label>Grupo de Proyecto</Label>
                            <Select/>
                        </div>

                        <div style={{flexBasis: '32%'}}>
                            <Label>Turno</Label>
                            <Select/>
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{flexBasis: '32%'}}>
                            <Label>Nivel de Agrupación</Label>
                            <Select/>
                        </div>

                        <div style={{flexBasis: '64%'}}>
                            <Label>Asunto</Label>
                            <Text/>
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{flexBasis: '100%'}}>
                            <Label>Nivel de Agrupación</Label>
                            <TextArea/>
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{flexBasis: '100%'}}>
                            <Label>Días de Envío de Reporte</Label>
                            <DateSelect form={form} setForm={setForm}/>
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-between mt-4'}>
                        <div style={{flexBasis: '50%'}}>
                            <div className={'d-flex justify-content-between align-items-center'}>
                                <div>
                                    <Label>Primer Envío</Label>
                                    <TimePicker/>
                                </div>

                                <div>
                                    <Label>Segundo Envío</Label>
                                    <TimePicker/>
                                </div>

                                <div>
                                    <Label>Tercer Envío</Label>
                                    <TimePicker/>
                                </div>
                            </div>
                        </div>
                        <div style={{flexBasis: '45%'}}>
                            <Label>Mostrar</Label>
                            <CovidSelect form={form} setForm={setForm}/>
                        </div>
                    </div>

                    <div className={'d-flex align-items-center w-100 justify-content-end mt-3'}>
                        <div className={styles.button} onClick={submit}>
                            <FontAwesomeIcon icon={faSave} color={'#52A035'}/>
                            <div className={'ps-2'}>Guardar</div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}